import { css } from '@emotion/core';
import styled from '@emotion/styled';
import { Link, navigate } from 'gatsby';
import React, { useEffect, useState } from 'react';
import { Menu, X } from 'react-feather';
import Logo from '../images/logo.svg';
import { Container } from './global';

export default function Navigation() {
  const [isAtTop, setIsAtTop] = useState(true);
  const [isMobileMenuOpen, setIsMobileMenuOpen] = useState(false);

  useEffect(() => {
    function handleScroll() {
      const scrollTop = window.pageYOffset;

      setIsAtTop(scrollTop <= 32);
    }

    window.addEventListener('scroll', handleScroll);

    return () => {
      window.removeEventListener('scroll', handleScroll);
    };
  }, []);

  // const NavList = ({ mobile }: { mobile?: boolean }) => (
  //   <NavListWrapper mobile={mobile}>
  //     <ul>
  //       <NavItem>
  //         <Link to="/features">Features</Link>
  //       </NavItem>
  //       <NavItem>
  //         <Link to="/pricing">Pricing</Link>
  //       </NavItem>
  //     </ul>
  //   </NavListWrapper>
  // );

  return (
    <Nav isAtTop={isAtTop}>
      <StyledContainer>
        <Brand>
          <ul>
            <Link to="/">
              <Logo style={{ width: 150 }} />
            </Link>
          </ul>
        </Brand>
        {/* <Mobile>
          <button
            type="button"
            onClick={() => setIsMobileMenuOpen(!isMobileMenuOpen)}
            style={{ color: 'black', background: 'none' }}
          >
            {isMobileMenuOpen ? <X size={24} /> : <Menu size={24} />}
          </button>
        </Mobile> */}
        {/* <Mobile hide>
          <NavList />
        </Mobile> */}
        <ActionsContainer>
          {/* <NavLink to="/login">Sign in</NavLink> */}
          <button type="button" onClick={() => navigate('/sign-up')}>
            Sign up
          </button>
        </ActionsContainer>
      </StyledContainer>
      {/* <Mobile>
        {isMobileMenuOpen && (
          <MobileMenu>
            <Container>
              <NavList mobile />
            </Container>
          </MobileMenu>
        )}
      </Mobile> */}
    </Nav>
  );
}

export const Nav = styled.nav<{ isAtTop: boolean }>`
  padding: ${({ isAtTop }) => (isAtTop ? `24px 0` : `16px 0`)};
  position: fixed;
  width: 100%;
  top: 0;
  z-index: 1000;
  background: ${({ isAtTop }) => (isAtTop ? null : 'white')};
  transition: 0.4s cubic-bezier(0.2, 0.8, 0.2, 1);
`;

export const StyledContainer = styled(Container)`
  display: flex;
  justify-content: space-between;
  align-items: center;
`;

export const NavListWrapper = styled.div<{ mobile?: boolean }>`
  ul {
    list-style: none;
    margin: 0;
    padding: 0;
    display: flex;
    flex-direction: row;

    ${({ mobile }) =>
      mobile &&
      css`
        flex-direction: column;
        margin-top: 1em;

        > ${NavItem} {
          margin: 0;
          margin-top: 0.75em;
        }
      `};
  }
`;

export const NavItem = styled.li`
  margin: 0 0.75em;
  font-family: ${props => props.theme.font.medium};
  ${props => props.theme.fontSize.xsmall};

  a {
    text-decoration: none;
    opacity: 0.9;
    color: ${props => props.theme.color.black.regular};
  }

  &.active {
    a {
      opacity: 1;
    }
  }
`;

export const MobileMenu = styled.div`
  width: 100%;
  height: 100vh;
  z-index: 1000;
  background: ${props => props.theme.color.background.white};
`;

export const Brand = styled.div`
  font-family: ${props => props.theme.font.extrabold};
  ${props => props.theme.fontSize.regular};
  color: ${props => props.theme.color.black.regular};
  text-decoration: none;
  letter-spacing: 1px;
  margin: 0;
  ul {
    list-style: none;
    margin: 0;
    padding: 0;

    a {
      color: ${props => props.theme.color.black.regular};
      text-decoration: none;
    }
  }
`;

export const ActionsContainer = styled.div`
  display: flex;
  align-items: center;
  /* @media (max-width: ${props => props.theme.screen.xs}) {
    display: none;
  } */

  button {
    font-family: ${props => props.theme.font.normal};
    ${props => props.theme.fontSize.xsmall};
    color: white;
    background: ${props => props.theme.color.secondary};
    border-radius: 4px;
    padding: 10px 16px;
    text-transform: uppercase;
    font-size: 12px;
  }
`;

export const Mobile = styled.div<{ hide?: boolean }>`
  display: none;

  @media (max-width: ${props => props.theme.screen.xs}) {
    display: block;
  }

  ${props =>
    props.hide &&
    css`
      display: block;

      @media (max-width: ${props.theme.screen.xs}) {
        display: none;
      }
    `}
`;

const NavLink = styled(Link)`
  color: ${props => props.theme.color.secondary};
  padding-bottom: 1px;
  margin: 0 18px;
  text-decoration: none;
  text-transform: uppercase;
  font-size: 12px;
`;
