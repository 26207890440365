import { ThemeProvider } from 'emotion-theming';
import React, { ReactNode } from 'react';
import '../static/fonts/fonts.css';
import GlobalStyles from '../styles/global-styles';
import { theme } from '../styles/theme';

type Props = {
  children: ReactNode;
};

export default function Layout({ children }: Props) {
  return (
    <ThemeProvider theme={theme}>
      <GlobalStyles />
      {children}
    </ThemeProvider>
  );
}
