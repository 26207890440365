export type Theme = typeof theme;

export const theme = {
  font: {
    primary: `'HK Grotesk Normal'`,
    secondary: `'HK Grotesk Medium'`,
    light: `'HK Grotesk Light'`,
    normal: `'HK Grotesk Normal'`,
    medium: `'HK Grotesk Medium'`,
    semibold: `'HK Grotesk Semibold'`,
    bold: `'HK Grotesk Bold'`,
    extrabold: `'HK Grotesk Extra Bold'`,
  },
  fontSize: {
    xxxsmall: 'font-size: 12px;',
    xxsmall: 'font-size: 14px;',
    xsmall: 'font-size: 16px;',
    small: 'font-size: 17px;',
    regular: 'font-size: 22px; line-height: 30px;',
    large: 'font-size: 28px; line-height: 30px;',
    larger: 'font-size: 40px; line-height: 50px;',
    xlarge: 'font-size: 48px; line-height: 48px;',
  },

  color: {
    primary: '#071435',
    secondary: '#125EB0',
    accent: '#F3A345',
    background: {
      white: '#ffffff',
      light: '#f8f8f8',
    },
    white: {
      regular: '#ffffff',
      lessdark: '#faf9f8',
      dark: '#f6f6f6',
      darker: '#eeeeee',
    },
    black: {
      lightest: '#ABA8AF',
      light: '#564F62',
      regular: '#071435',
    },
  },
  screen: {
    xs: '575px',
    sm: '767px',
    md: '991px',
    lg: '1199px',
  },
};
